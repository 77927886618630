import React from 'react';
import styled from 'styled-components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ActionButton } from '../components';

const ButtonHolder = styled.div`
  margin-bottom: 1em;
`;

const ReadMoreButton = (props: { action: () => void }) => (
  <ActionButton onClick={props.action} style={{ display: 'flex' }}>
    Read more
    <ExpandMoreIcon />
  </ActionButton>
);

type Props = {};
type State = {
  expanded: boolean;
};

class ReadMoreWrapper extends React.Component<Props, State> {
  readonly state: Readonly<State> = {
    expanded: false,
  };

  render() {
    return (
      <>
        <ButtonHolder style={{ display: !this.state.expanded ? 'inline-block' : 'none' }}>
          <ReadMoreButton action={() => this.setState({ expanded: true })} />
        </ButtonHolder>
        <div
          style={{
            opacity: this.state.expanded ? 1 : 0,
            position: this.state.expanded ? 'static' : 'absolute',
            transition: 'opacity 1s ease-out',
            // hidden read more should not be rendered above other content (otherwise will swallow click events)
            zIndex: -1,
            // hidden read more should not inflate page width (otherwise will cause horizontal scroll on small screens/mobile devices)
            width: '100%',
          }}
        >
          {this.props.children}
        </div>
      </>
    );
  }
}

export default ReadMoreWrapper;
