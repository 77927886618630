import React, { Children } from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import { Layout, SEO, ActionButton, SecondaryActionButton, ButtonRow } from '../components';
import Navigation from '../components/Navigation';
import Helmet from 'react-helmet';
import theme from '../../config/Theme';
import config from '../../config/SiteConfig';
import { GatsbyImage, ImageDataLike, getImage } from 'gatsby-plugin-image';
import AllMarkdownRemark from '../models/AllMarkdownRemark';
import PathContext from '../models/PathContext';
import PageResources from '../models/PageResources';
import ReadMore from '../components/ReadMore';
import { media } from '../utils/media';
import Scrollspy from 'react-scrollspy';
import { Tweet } from 'react-twitter-widgets';

const Avatar = styled(GatsbyImage)`
  width: 150px;
  max-width: 150px;
  max-height: 150px;
  margin: 25px 0;
  border-radius: 50%;
  border: 2px solid #7447d3;
  img {
    // for iOS: need to style the inner image in addition to outer wrapper
    border-radius: 50%;
    border: 2px solid #7447d3;
  }
`;

const IntroText = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  color: #263169; // #7447d3;
  margin: 0;
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 600;
`;

// may optionally be followed by SectionSubtitle
const SectionTitle = styled.h1`
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  color: #101828;
  margin: 0;
  margin-bottom: 32px;
`;

// always preceded by a SectionTitle
const SectionSubtitle = styled.h3`
  font-weight: 400;
  font-size: 27px;
  line-height: 36px;
  color: #8899a8;
  margin-top: -28px; // 4px effectively
  margin-bottom: 32px;
`;

const SectionPill = styled.h4`
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 24px;
  color: #9159ee;
  transform: uppercase;
  margin-top: 0;
  margin-bottom: 9px;
`;

const Separator = styled.div`
  width: 100%;
  height: 2px;
  background: #667085;
  margin-top: 75px;
  margin-bottom: 43px;
`;

const Card = styled.div`
  width: 720px;
  max-width: 100%;
  padding: 60px;
  background: #eae5fe;
  border-radius: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  color: #9476d5;
  margin-top: 50px;
  margin-bottom: 100px;
  strong {
    font-weight: 500;
    color: #6941c6;
  }
`;

const CardButton = styled.a`
  display: inline-block;
  border-radius: 10px;
  padding: 10px 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  margin: 0;
  margin-right: 15px;
  text-align: center;
  text-decoration: none;
  box-shadow: none;
  &:hover,
  &:visited,
  &:link,
  &:active,
  &:focus {
    text-decoration: none;
    box-shadow: none;
    transition: all ${theme.transitions.normal};
  }
  transition: all ${theme.transitions.normal};
`;

const PrimaryCardButton = styled(CardButton)`
  background: #7f56d9; // #7447d3;
  color: #fff; // #e6e2ed;
  &:hover,
  &:hover strong {
    background: #6941c6;
    color: #fff;
  }
`;

const SecondaryCardButton = styled(CardButton)`
  background: #f8f3ff;
  color: #6941c6; // #9476d5;
  &:hover {
    background: #f4ebff; // #e9d7fe; // #f9fafb; // #f9f5ff; // #f4ebff;
  }
`;

const SidebarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const HeroRow = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  margin: 0 auto;

  @media ${media.tablet} {
    // justify-content: left;
    flex-direction: column;
    align-items: baseline;
  }

  width: 1200px;
  max-width: 100%;
  padding: 40px;
  // background: #f7fafc; // #eae5fe;
  border-radius: 25px;
  // font-style: normal;
  // font-weight: 400;
  // font-size: 20px;
  // line-height: 130%;
  // color: #9476d5;
  margin-top: 50px;
  margin-bottom: 100px;
  // strong {
  //   font-weight: 500;
  //   color: #6941c6;
  // }

  // background-color: #4158d0;
  // background-image: linear-gradient(43deg, #4158d0 0%, #c850c0 46%, #ffcc70 100%);

  // background-color: #4158d0;
  // background-image: linear-gradient(43deg, #4158d0 0%, #c850c0 16%, #ffcc70 100%);

  // background-color: #0093e9;
  // background-image: linear-gradient(43deg, #0093e9 0%, #c850c0 16%, #ffcc70 100%);

  // background-color: #eae5fe;
  // background-image: linear-gradient(43deg, #eae5fe 0%, #c850c0 7%, #ffcc70 90%, #0093e9 100%);

  // background-image: linear-gradient(109.6deg, rgba(218, 185, 252, 1) 11.2%, rgba(125, 89, 252, 1) 91.1%);

  // background-color: #eae5fe;
  // background-image: linear-gradient(43deg, #eae5fe 0%, #c850c0 7%, #ffcc70 94%, #0093e9 100%);

  // Finally one I like:
  // background-color: rgba(253, 199, 141, 1);
  // background-image: linear-gradient(109.6deg, rgba(253, 199, 141, 1) 11.3%, rgba(249, 143, 253, 1) 100.2%);

  // Less dramatic alternatives:
  // background-color: rgba(200, 230, 201, 1);
  // background-image: linear-gradient(109.6deg, rgba(200, 230, 201, 1) 11.3%, rgba(255, 210, 210, 1) 100.2%);

  // background-color: rgba(255, 182, 159, 1);
  // background-image: linear-gradient(109.6deg, rgba(255, 182, 159, 1) 11.3%, rgba(255, 218, 218, 1) 100.2%);

  // background-color: rgba(223, 206, 233, 1);
  // background-image: linear-gradient(109.6deg, rgba(223, 206, 233, 1) 11.3%, rgba(255, 206, 216, 1) 100.2%);

  // background-color: rgba(255, 242, 204, 1);
  // background-image: linear-gradient(109.6deg, rgba(255, 242, 204, 1) 11.3%, rgba(255, 182, 159, 1) 100.2%);

  // A bit better:
  // background-color: rgba(206, 190, 228, 1);
  // background-image: linear-gradient(109.6deg, rgba(206, 190, 228, 1) 11.3%, rgba(255, 204, 218, 1) 100.2%);

  // Better variant:
  background-color: rgba(208, 191, 236, 1);
  background-image: linear-gradient(109.6deg, rgba(208, 191, 236, 1) 11.3%, rgba(255, 182, 159, 1) 100.2%);

  // Reversed:
  background-color: rgba(255, 182, 159, 1);
  background-image: linear-gradient(-70.4deg, rgba(255, 182, 159, 1) 11.3%, rgba(208, 191, 236, 1) 100.2%);

  // How about reversing this one?
  // background-color: rgba(176, 159, 233, 1);
  // background-image: linear-gradient(109.6deg, rgba(176, 159, 233, 1) 11.3%, rgba(203, 221, 244, 1) 100.2%);

  // Reversing is ugly:
  // background-color: rgba(203, 221, 244, 1);
  // background-image: linear-gradient(-70.4deg, rgba(203, 221, 244, 1) 11.3%, rgba(176, 159, 233, 1) 100.2%);
`;

const LeftContent = styled.div`
  // @media (max-width: '800px') {
  @media ${media.sidebar} {
    width: 100%;
  }
  width: 800px;
`;

const RightContent = styled.div`
  width: 220px;
`;

const AvatarHolder = styled.div`
  width: 120px;
`;

const IntroTextHolder = styled.div`
  // @media (max-width: '800px') {
  @media ${media.tablet} {
    width: 100%;
  }
  width: 800px;
`;

const Sidebar = styled(RightContent)`
  @media ${media.sidebar} {
    display: none;
  }
  margin-top: 0px; // how much to offset from top of div, before scroll
  // padding: 15px 0 0 0;

  // height: 35vh; // what should this be set to?
  // min-height: 400px; // 500px; // how long the vertical bar is
  height: 100%;

  position: -webkit-sticky;
  position: sticky;
  top: 80px; // how much to offset from top when scrolled
  color: #8899a8;

  border-left: 2px solid #d3dce3;

  h4 {
    margin: 0;
    // padding: 10px 0 2px 15px;
    padding: 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #8899a8;
    // text-transform: uppercase;
    // padding-left: 10px;
    padding: 10px 10px;
    // margin-bottom: 20px;
  }

  h5 {
    margin: 0;
    // padding: 10px 0 2px 15px;
    // padding: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #8899a8;
    // text-transform: uppercase;
    // padding-left: 24px;
    padding: 10px 10px;
    // margin-bottom: 20px;

    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 5px;
    font-family: '${config.bodyFontFamily}', sans-serif;
    font-weight: 500;
    line-height: 1.82;
    font-size: 17px;
  }
`;

const SidebarNavigationList = styled(Scrollspy)`
  color: #8899a8;
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    // padding: 0.1ex 0;
    margin: 0;
    padding: 0;
    padding: 10px 0;
    font-weight: 400;
    padding-left: 24px;

    padding-left: 34px;
    padding-bottom: 8px;

    &.active-item,
    &:active,
    &:hover {
      background: #eceff1;
      border-left: 3px solid #804bf4;
      margin-left: -4px;
      color: #9159ee;
      a {
        color: #9159ee;
      }
    }
    a {
      color: #8899a8;
      text-decoration: none;
      box-shadow: none;
      // font-size: 1em;
      font-size: 17px;
      display: block;
      // padding: 8px 20px;
      background: inherit;
      transition: none;
      &:hover,
      &:active,
      &:hover strong {
        color: #9159ee;
        box-shadow: none;
      }
    }
  }
`;

const FancyLists = styled.div`
  li {
    width: 680px; // not sure
    max-width: 100%; // on small screen sizes, prevent going over the edge due to ::before
    list-style: none;
    ::before {
      // box-sizing: border-box;
      // display: inline;
      position: absolute;
      border-radius: 50%;
      background-color: #cfd7e0;
      content: '';
      margin-top: 12px;
      margin-left: -24px;
      // top: 11px;
      // left: -23px;
      width: 10px;
      height: 10px;
      @media ${media.phone} {
        margin-top: 10px;
      }
    }
    // list-style: disc;
    // ::marker {
    //   content: '•';
    //   padding-inline-start: 1ch;
    //   border-radius: 1ch;
    //   padding-inline: 1ch;
    //   margin-inline-end: 1ch;
    //   // content: '';
    //   border-radius: 50%;
    //   background-color: #cfd7e0;
    //   width: 10px;
    //   height: 10px;
    // }
  }
`;

const MarkdownText = styled(FancyLists)`
  & > :first-child {
    // Reduce margins that may come from having h1/h2/h3/h4 be the first element
    margin-top: 1em;
  }
  h1,
  h2,
  h3,
  h4 {
    margin-bottom: 10px; // override standard 1.82rem margin-bottom because will be followed by p
  }
  p {
    margin-bottom: 28px; // paragraph spacing
  }
`;

const MarkdownBlock = (props: { markdown: MarkdownData; id?: string }) => {
  const contents = { __html: props.markdown.childMarkdownRemark.html };
  return <MarkdownText id={props.id} className="yellowLinks" dangerouslySetInnerHTML={contents} />;
};

interface MarkdownData {
  childMarkdownRemark: {
    html: string;
  };
}

interface HomePageProps {
  data: {
    about: MarkdownData;
    products: MarkdownData;
    products2: MarkdownData;
    products3: MarkdownData;
    products4: MarkdownData;
    diagnostics: MarkdownData;
    research: MarkdownData;
    researchMore: MarkdownData;
    projects: MarkdownData;
    software: MarkdownData;
    allPosts: AllMarkdownRemark;
    avatar: ImageDataLike;
  };
  location: Location;
  pageResources?: PageResources;
  pathContext: PathContext;
}

const ExternalLinks = () => (
  <Card>
    <p>
      <strong>Please get in touch.</strong>
      <br />I live in New York and would love to meet.
    </p>
    <ButtonRow>
      <PrimaryCardButton href="mailto:maxim@maximz.com" target="_blank">
        Email
      </PrimaryCardButton>
      <SecondaryCardButton href="https://www.linkedin.com/in/maximzaslavsky" target="_blank">
        LinkedIn
      </SecondaryCardButton>
      <SecondaryCardButton href="https://github.com/maximz" target="_blank">
        GitHub
      </SecondaryCardButton>
    </ButtonRow>
  </Card>
);

export default class IndexPage extends React.Component<HomePageProps> {
  public render() {
    const { about, products, products2, products3, products4, diagnostics, research, researchMore, projects, software, allPosts, avatar } =
      this.props.data;
    const avatarImage = getImage(avatar);
    return (
      <Layout>
        <SEO />
        <Helmet title={`${config.siteTitle}`}></Helmet>
        <Navigation />
        <HeroRow>
          <AvatarHolder>
            <Avatar image={avatarImage} alt="" />
          </AvatarHolder>
          <IntroTextHolder>
            {/* <SectionPill>ABOUT</SectionPill> */}
            <IntroText>I bring together computer science and immunology to build data-driven medical diagnostics.</IntroText>
          </IntroTextHolder>
        </HeroRow>
        <SidebarWrapper>
          <LeftContent>
            <div id="about">
              <MarkdownBlock markdown={about} />
              <ExternalLinks />
            </div>
            <div id="research">
              <div id="malid">
                <SectionPill>RESEARCH</SectionPill>
                <SectionTitle>Reading the immune system's records to diagnose disease</SectionTitle>
                {/* <SectionSubtitle>Now</SectionSubtitle> */}
                <MarkdownBlock markdown={diagnostics} />
                {/* <iframe
                  style={{ borderRadius: '12px' }}
                  src="https://open.spotify.com/embed/episode/1FDN6kHs40JWAJd8mBgWN4?utm_source=generator"
                  width="100%"
                  height="152"
                  frameBorder="0"
                  allowFullScreen
                  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                  loading="lazy"
                ></iframe>
                <iframe
                  style={{ borderRadius: '12px' }}
                  src="https://open.spotify.com/embed/episode/1FDN6kHs40JWAJd8mBgWN4?utm_source=generator&theme=0"
                  width="100%"
                  height="152"
                  frameBorder="0"
                  allowFullScreen
                  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                  loading="lazy"
                ></iframe>
                <iframe
                  allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write"
                  frameBorder="0"
                  height="175"
                  style={{ width: '100%', maxWidth: '660px', overflow: 'hidden', borderRadius: '10px'}}
                  loading="lazy"
                  sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
                  src="https://embed.podcasts.apple.com/us/podcast/on-airr-13-disease-diagnostics-using-machine-learning/id1615523185?i=1000619029194"
                ></iframe> */}
                <div style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', maxWidth: '550px' }}>
                  <iframe
                    allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write"
                    frameBorder="0"
                    height="175"
                    style={{ width: '100%', maxWidth: '550px', overflow: 'hidden', borderRadius: '10px' }}
                    loading="lazy"
                    sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
                    src="https://embed.podcasts.apple.com/us/podcast/on-airr-13-disease-diagnostics-using-machine-learning/id1615523185?i=1000619029194"
                  ></iframe>
                </div>
                <Tweet
                  tweetId="1625240739235106816"
                  options={{
                    align: 'center',
                  }}
                />
                <iframe
                  // width="1512"
                  // height="627"
                  width="100%"
                  height="450"
                  src="https://www.youtube.com/embed/ksCSYCaEakA"
                  title="Maxim Zaslavsky's PhD defense"
                  frameBorder="0"
                  loading="lazy"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
                <ButtonRow>
                  <ActionButton href="https://www.biorxiv.org/content/10.1101/2022.04.26.489314" target="_blank">
                    Read preprint
                  </ActionButton>
                  {/* <SecondaryActionButton href="https://twitter.com/zazius/status/1625240739235106816" target="_blank">
                    Twitter summary
                  </SecondaryActionButton> */}
                  <ActionButton href="https://scholar.google.com/citations?user=ltz0QYMAAAAJ" target="_blank">
                    See other publications and patents
                  </ActionButton>
                  {/* TODO: Consider SecondaryActionButton here instead */}
                </ButtonRow>
              </div>
              <Separator />
              {/* <div id="research2">
                <SectionPill>RESEARCH</SectionPill>
                <SectionTitle>
                  Measuring immune cells that infiltrate the tumor microenvironment, with Bayesian mixture deconvolution
                </SectionTitle>
                <SectionSubtitle>2016 — 2017</SectionSubtitle>
                <MarkdownBlock markdown={research} />
                <ReadMore>
                  <MarkdownBlock markdown={researchMore} />
                  <ButtonRow>
                    <ActionButton href="https://www.biorxiv.org/content/early/2017/11/21/221671" target="_blank">Preprint</ActionButton>
                    <SecondaryActionButton href="https://github.com/hammerlab/infino/tree/develop" target="_blank">Code</SecondaryActionButton>
                  </ButtonRow>
                </ReadMore>
              </div>
              <Separator /> */}
            </div>
            <div id="products">
              <div id="biq">
                <SectionPill>PRODUCTS</SectionPill>
                <SectionTitle>Butterfly iQ</SectionTitle>
                <SectionSubtitle>2018 — 2019</SectionSubtitle>
                <MarkdownBlock markdown={products} />
                <ReadMore>
                  <MarkdownBlock markdown={products2} />
                </ReadMore>
              </div>
              <Separator />
              <div id="btele">
                <SectionPill>PRODUCTS</SectionPill>
                <SectionTitle>Butterfly Tele-Guidance</SectionTitle>
                <SectionSubtitle>2018</SectionSubtitle>
                <MarkdownBlock markdown={products3} />
                <ReadMore>
                  <MarkdownBlock markdown={products4} />
                </ReadMore>
              </div>
              <Separator />
            </div>
            {/* <div id="projects">
              <SectionPill>PERSONAL</SectionPill>
              <SectionTitle>Projects</SectionTitle>
              <MarkdownBlock markdown={projects} />
            </div>
            <Separator /> */}
            {/* <div id="software">
              <SectionPill>PERSONAL</SectionPill>
              <SectionTitle>Open source software</SectionTitle>
              <MarkdownBlock markdown={software} />
            </div>
            <Separator /> */}
            <div id="personal">
              {/* <SectionPill>PERSONAL</SectionPill>
              <SectionTitle>Writing</SectionTitle>
              <p>
                Every year or two, I write an update letter to stay in touch with old friends and mentors. I post them here because they’re
                a chronicle of what I’m working on and thinking about now:
              </p>
              <FancyLists>
                <ul>
                  {allPosts.edges.map((post) => (
                    <li>
                      <Link to={`/blog/${post.node.fields.slug}`}>{post.node.frontmatter.title}</Link>
                    </li>
                  ))}
                  <li>
                    <a href="https://static.maximzaslavsky.com/maxim_zaslavsky_2014.pdf">Maxim's 2014</a>
                  </li>
                  <li>
                    <a href="https://static.maximzaslavsky.com/maxim_zaslavsky_2013.pdf">Maxim's 2013</a>
                  </li>
                </ul>
              </FancyLists>
              <Separator /> */}
              <SectionPill>PERSONAL</SectionPill>
              <SectionTitle>Music</SectionTitle>
              <p>Here's a recent jazz piano performance with my band at Stanford.</p>
              <iframe
                // width="1512"
                // height="627"
                width="100%"
                height="450"
                src="https://www.youtube.com/embed/r-TNxT3o1Q8?start=15"
                title="Well You Needn&#39;t"
                frameBorder="0"
                loading="lazy"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </LeftContent>
          <Sidebar>
            {/* <h4>Contents</h4> */}
            {/* <ul> */}
            <SidebarNavigationList
              // add "doesnotexist" for any h5 separators
              items={['about', 'doesnotexist', 'malid', 'doesnotexist', 'biq', 'btele', 'doesnotexist', 'personal']}
              // 'projects', 'software'
              currentClassName="active-item"
              offset={-500}
            >
              <li
                style={{
                  paddingLeft: '15px',
                  fontWeight: 500,
                  fontSize: '16px',
                  paddingTop: '10px',
                  paddingBottom: '8px',
                  lineHeight: '1.82',
                }}
              >
                {/* lineHeight: '24px', */}
                <Link to="/#top">About</Link>
              </li>
              <h5>Research</h5>
              <li>
                <Link to="/#malid">Immune diagnostics</Link>
              </li>
              <h5>Products</h5>
              <li>
                <Link to="/#biq">Ultrasound on iPhone</Link>
              </li>
              <li>
                {/* TODO: drop this once we bring back projects and software */}
                <Link to="/#btele">Ultrasound, remotely</Link>
              </li>
              <h5>Personal</h5>
              {/* <li>
                <Link to="/#projects">Side projects</Link>
              </li>
              <li>
                <Link to="/#software">Software</Link>
              </li> */}
              <li>
                <Link to="/#personal">Music</Link>
              </li>
            </SidebarNavigationList>
          </Sidebar>
        </SidebarWrapper>
        {/*
        <Section gray={!initialRowGray} name="Writing." id="writing">

        </Section> */}
      </Layout>
    );
  }
}

export const squareImage = graphql`
  fragment squareImage on File {
    childImageSharp {
      gatsbyImageData(width: 150, height: 150, placeholder: BLURRED)
    }
  }
`;

export const IndexQuery = graphql`
  fragment MarkdownContent on File {
    childMarkdownRemark {
      html
    }
  }
  query {
    avatar: file(relativePath: { eq: "maximz_2022_headshot1_3.jpg" }) {
      ...squareImage
    }
    about: file(relativePath: { eq: "about.md" }) {
      ...MarkdownContent
    }
    products: file(relativePath: { eq: "products.md" }) {
      ...MarkdownContent
    }
    products2: file(relativePath: { eq: "products_2.md" }) {
      ...MarkdownContent
    }
    products3: file(relativePath: { eq: "products_3.md" }) {
      ...MarkdownContent
    }
    products4: file(relativePath: { eq: "products_4.md" }) {
      ...MarkdownContent
    }
    diagnostics: file(relativePath: { eq: "diagnostics.md" }) {
      ...MarkdownContent
    }
    research: file(relativePath: { eq: "research.md" }) {
      ...MarkdownContent
    }
    researchMore: file(relativePath: { eq: "research_more.md" }) {
      ...MarkdownContent
    }
    projects: file(relativePath: { eq: "projects.md" }) {
      ...MarkdownContent
    }
    software: file(relativePath: { eq: "software.md" }) {
      ...MarkdownContent
    }
    allPosts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 1000
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "DD.MM.YYYY")
            published
          }
          timeToRead
        }
      }
    }
  }
`;
